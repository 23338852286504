/*--------------------------------------------------------------------------*
 * ハンバーガーメニュー
 *--------------------------------------------------------------------------*/
$(function () {
    $('[data-nav-trigger]').on('click', function () {
        $(this).toggleClass('active');
        $('[data-nav]').slideToggle(300);

        if ($(this).hasClass('active')) {
            $('[data-nav-label]').text('CLOSE');
        } else {
            $('[data-nav-label]').text('MENU');
        }
    });
    if ($(window).width() < 1024) {
        $('.p-gnav-list-item a').on('click', function () {
            $('[data-nav]').slideToggle(300);
            $('[data-nav-trigger]').toggleClass('active');
            if ($(this).hasClass('active')) {
                $('[data-nav-label]').text('CLOSE');
            } else {
                $('[data-nav-label]').text('MENU');
            }
        });
    }
});
  

